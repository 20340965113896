<template>
  <div>
    <div style="background:#03063c">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link :to="{ path: '/components/web/itc/case01' }">
            <li class="tabStyleLi">
              <div class="tabStyle" style="background:#3246F4"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.quanfangweiitc') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case02' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.quanqiuopo') }}</div>
              </div>
            </li>
          </router-link>
          <router-link :to="{ path: '/components/web/itc/case03' }">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.anlifenxiang') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/itc/index' }">
          <span style="color:#303133">{{
            $t('Nav.itc')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">
            {{ $t('Nav.quanfangweiitc') }}</span
          ></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="height:30px"></div>
    <div style="width:1200px; margin:0 auto;">
      <el-row :gutter="20">
        <router-link :to="{ path: '/components/web/itc/case01-1' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(1)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case01"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>
              <transition name="el-zoom-in-center">
                <div
                  v-show="showText"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:70px;margin-bottom:30px;"
                    ></div>
                    <div class="imgText">{{ $t('Nav.itccase01_1') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/itc/case01-2' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(2)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case02"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>
              <transition name="el-zoom-in-center">
                <div
                  v-show="showText2"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px; background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:70px;margin-bottom:30px;"
                    ></div>
                    <div class="imgText">{{ $t('Nav.itccase02_1') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
        <router-link :to="{ path: '/components/web/itc/case01-3' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(3)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case03"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>
              <transition name="el-zoom-in-center">
                <div
                  v-show="showText3"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px;background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:70px;margin-bottom:30px;"
                    ></div>
                    <div class="imgText">{{ $t('Nav.itccase03_1') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
      </el-row>
      <el-row :gutter="20">
        <router-link :to="{ path: '/components/web/itc/case01-4' }">
          <el-col :span="8"
            ><div
              class="grid-content2"
              @mouseenter="showTextFun(4)"
              @mouseleave="hideTextFun"
            >
              <el-image
                :src="case04"
                fit="contain"
                style="width:100%; height:300px"
              ></el-image>
              <transition name="el-zoom-in-center">
                <div
                  v-show="showText4"
                  style="position: absolute;top:20px;left:0; width:100%; height:260px;background:#182AC3; opacity:0.7"
                >
                  <div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:70px;margin-bottom:30px;"
                    ></div>
                    <div class="imgText">{{ $t('Nav.itccase04_1') }}</div>
                    <div
                      style="display:inline-block; border-top:1px solid #ffffff; width:50px;margin-top:30px"
                    ></div>
                  </div>
                </div>
              </transition></div
          ></el-col>
        </router-link>
      </el-row>
    </div>

    <div style="height:30px; margin-top:30px"></div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/44.jpg'),
      corporateVision1: require('../../../assets/jifang.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
      case01: require('../../../assets/itc/1.jpg'),
      showText: false,
      case02: require('../../../assets/itc/2.jpg'),
      showText2: false,
      case03: require('../../../assets/itc/3.jpg'),
      showText3: false,
      case04: require('../../../assets/itc/4.jpg'),
      showText4: false,
      case05: require('../../../assets/itc/5.jpg'),
      showText5: false,
      case06: require('../../../assets/itc/6.jpg'),
      showText6: false,
      case07: require('../../../assets/itc/7.jpg'),
      showText7: false,
    }
  },
  methods: {
    showTextFun(num) {
      if (num == 1) {
        this.showText = true
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
      } else if (num == 2) {
        this.showText = false
        this.showText2 = true
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
      } else if (num == 3) {
        this.showText = false
        this.showText2 = false
        this.showText3 = true
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
      } else if (num == 4) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = true
        this.showText5 = false
        this.showText6 = false
        this.showText7 = false
      } else if (num == 5) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = true
        this.showText6 = false
        this.showText7 = false
      } else if (num == 6) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = true
        this.showText7 = false
      } else if (num == 7) {
        this.showText = false
        this.showText2 = false
        this.showText3 = false
        this.showText4 = false
        this.showText5 = false
        this.showText6 = false
        this.showText7 = true
      }
    },
    hideTextFun() {
      this.showText = false
      this.showText2 = false
      this.showText3 = false
      this.showText4 = false
      this.showText5 = false
      this.showText6 = false
      this.showText7 = false
    },
  },
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
